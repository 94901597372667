.form-centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}
.bottom-right {
  align-self: flex-end;
}
.page-content {
  flex: 1 0 auto;
}

.container-fluid {
  padding-right: 5;
  padding-left: 5;
  margin-right: auto;
  margin-left: auto;
}

html,
body,
#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.footer {
  background-color: rgb(235, 237, 239);
}
.login-box img {
  max-height: 150px;
}

.login-box {
  text-align: center;
}

.login-view .input-group-prepend .input-group-text,
.register-view .input-group-prepend .input-group-text,
.verify-view .input-group-prepend .input-group-text {
  min-width: auto;
}
.list-group-striped .list-group-item:nth-of-type(even) {
  background: rgba($color: #212529, $alpha: 0.05);
}
